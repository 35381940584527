import { Component } from "react";

var home, vehicles, locations, about_us, get_tarterd, intro_title, intro_subtitle, home_title,individual, emotional, luxury, need_driver_title, need_driver_text, read_more, renting_car_title, renting_car_text,
reserve_now, legal_notice, cookies, privacy_statement, contact, copy_text, created_by, vehicles_title,v_class, exlusive, exciting_luxury, v_class_title, v_class_text1, v_class_text2,viano_title, viano_text,
locations_title,you_can, volvo_title, volvo_text, find_us, easily, address, phone, email,about_us_title, a_couple, of_words, about_us_text1, about_us_text2, about_us_text3 ,reserve_title, reserve_subtitle,
contact_name, date, pick_up_location, drop_location, pick_up_date, drop_date, vehicle, additions, comments, privacy_policy,cancel, send, required_field, select_pick_up_location, same_as_pick_up_location, 
select_vehicle, driver, guide, thankyou_title, thankyou_text,not_found_title, not_found_text, gallery_text,gallery_text_v_class, gallery_text_viano, gallery_text_volvo, gallery_text_vito, vehicles_text_bottom_p1, vehicles_text_bottom_p2, your_name, your_email,
your_phone,your_message, with_driver, additional_options, driver_included, guide_included, home_bottom_text, home_top_text,locations_subtitle,about_vip_cars_sarajevo,gallery_text_s_class,
logo_alt, phone_alt, reserve_alt, image_left1_alt, image_right1_alt, image_right2_alt, vehicles_alt, gallery_alt, vehicles_bottom_alt, vito_title, vito_text, s350d_title, s350d_text;
var langType;
if (typeof window !== 'undefined') {
langType = window.location.pathname.split('/');
langType = langType[1];
}
 if(langType == "en"){
    home = "Home";
    vehicles = "Vehicles";
    locations = "Location";
    about_us = "About us";
    get_tarterd = "Get started";
    intro_title = "Treat yourself with a luxury car and drive in style on your next trip.";
    intro_subtitle = "LUXURY CARS FOR RENT";
    home_title = "Individual, emotional and luxury";
    individual = "Individual";
    emotional = "emotional";
    luxury = "and luxury";
    need_driver_title = "Need driver?";
    need_driver_text = "In addition to our cars we provide you also with driver services. Considering security we offer you the professional drivers of the highest caliber who have passed the rigorous selection process.";
    read_more = "Read more";
    renting_car_title = "Renting a Car with VIP Cars";
    renting_car_text = "A trip to Bosnia, Croatia & Serbia - whether it is for business or pleasure - is an experience you won't soon forget. Mercedes V-Class, Mercedes Viano, Mercedes Vito, Volvo XC90 and other luxury brands are available for rent in any destination you choose. Just choose a vehicle that's ideally suited to your needs and unique style. See how much it cost to rent a car for a day, a weekend, a full week or more by sending an inquiry for additional information and a reservation.";
    reserve_now = "Reserve now";
    legal_notice = "Legal Notice";
    cookies = "Cookies";
    privacy_statement = "Privacy Statement";
    contact = "Contact";
    copy_text = "All rights reserved.";
    created_by = "Created by";
    vehicles_title = "The V-Class EXCLUSIVE - exciting luxury";
    v_class = "The VIP CARS";
    exlusive = "EXCLUSIVE";
    exciting_luxury = "exciting luxury";
    v_class_title = "Wow - Fascination V-Class";
    v_class_text1 = "Elegance, style, comfort and spaciousness define every V-Class in “VIP Cars Sarajevo“ offer. The universal concept provides precisely the right solution for everyone and every situation. Thanks to its innovative safety systems and spaciousness, the V-Class is also a perfect family vehicle.Mercedes-Benz presents luxury in a unique format with the V-Class EXCLUSIVE, the ultimate VIP shuttle and an exclusive vehicle for long journeys.";
    v_class_text2 = "The expressive interior gives the V-Class the exciting and unpredictable flair of a passenger vehicle combined with outstanding reliability. This VIP CARS luxury vehicle can be rented exclusively with one of our professional drivers.";
    viano_title = "A combination of elegance and comfort with Viano";
    viano_text = "The Viano is ready equipped with everything that a fully-fledged family car needs. With room for up to eight people and a highly flexible passenger compartment, it boasts all the right credentials for trips to the world’s essential destinations: from nursery schools and football pitches to shopping centres, holiday homes and amusement parks. You can rent a luxury vehicle Mercedes Viano with or without a professional driver.";
    volvo_title = "Magical - Volvo XC90";
    volvo_text = "Luxury sports utility vehicle XC90 is our ultimate luxury idea. A combination of perfect safety, sophisticated power, unmatched comfort, and sleek design allow you to drive in the first class every day. The interior of the XC90 will impress you with specially selected materials and intuitive technology, while driving will signify the sense of power and quiet of the cab. Luxury VIP CARS car Volvo XC90 can be rented with or without a professional driver.";
    vito_title = "One for all: The versatile Vito";
    vito_text = "A tough exterior and high-quality interior, maximum benefit and low costs – The Mercedes-Benz Vito is the versatile professional in the mid-size van segment between 2.5 t and 3.2 t gross vehicle weight. The variety and variability of the panel van, Mixto and Tourer, make the mid-size Mercedes-Benz van a specialist and all-rounder for a wide range of customer groups. As the first van in the mid-size van segment, it offers three drive types as well as many innovative safety and assistance systems.";
    locations_title = "You can FIND US easily";
    you_can = "Our";
    find_us = "OFFICE";
    easily = "Sarajevo - Unitic";
    address = "Address";
    phone = "Phone";
    email = "Email";
    about_us_title = "A couple of words ABOUT US";
    a_couple = "A couple";
    of_words = "of words";
    about_vip_cars_sarajevo = "ABOUT VIP CARS SARAJEVO";
    about_us_text1 = "VIP Cars is a luxury car rental that also offers driver services in addition to its own vehicles. The main office is in the heart of Sarajevo, but we can also provide rental services at any destination across BiH, Croatia and Serbia.";
    about_us_text2 = "Our goal is to be the best in this sector with a wide range of premium vehicles and quality service. Our clients can pick up a vehicle wherever they want, at the airport, at the bottom of the slopes of any ski resort, at the entrance to the villa or any hotel in any city. We can do this very efficiently in a very short period of time.";
    about_us_text3 = "All of our partners have many years of experience, thanks to which VIP Cars is focused on professional service and customer satisfaction. We guarantee exceptional quality of service, so each client can enjoy at any time.";
    reserve_title = "RESERVE A RENTAL CAR";
    reserve_subtitle = "Want to get started with an VIP rental? Have a question? Tell us how to reach you and we'll get back to you by the next business day.";
    contact_name = "Contact name";
    date = "Date";
    pick_up_date = "Pick up date";
    drop_date = "Drop date";
    pick_up_location = "Pick up location";
    drop_location = "Drop location";
    vehicle = "Vehicle";
    additions = "Additions";
    comments = "Comments";
    privacy_policy = "Privacy policy";
    cancel = "Cancel";
    send = "Send";
    required_field = "This field is required.";
    select_pick_up_location = "Select pick up location";
    same_as_pick_up_location = "Same as pick up location";
    select_vehicle = "Select vehicle";
    driver = "Driver";
    guide = "Guide";
    thankyou_title = "Thank you!";
    thankyou_text = "You form was submitted. We will contact you as soon as posible on your phone or email address.";
    not_found_title= "Error 404 - Page not found!";
    not_found_text = "Sorry, the page you are trying to reach does not exist. Try with some other pages from menu above.";
    gallery_text="gallery";
    gallery_text_v_class = "V-Class vehicles gallery";
    gallery_text_viano= "Viano vehicles gallery";
    gallery_text_s_class= "S Class vehicles gallery";
    gallery_text_vito = "Vito vehicles gallery";
    gallery_text_volvo = "Volvo vehicles gallery";
    vehicles_text_bottom_p1 = "In our offer, we also have vehicles which is not included in VIP category. For more informations, you can contact us by phone: ";
    vehicles_text_bottom_p2 = "or by email: ";
    your_name = "Your Name";
    your_email = "Your Email";
    your_phone = "Your Phone";
    your_message = "Your message";
    with_driver = "only with driver";
    additional_options = "Additional options";
    driver_included = "Driver is included in your offer now.";
    guide_included = "Guide is included in your offer now.";
    home_bottom_text = "VIP Cars is a luxury car rental that also offers driver services in addition to its own vehicles. The main office is in the heart of Sarajevo (Bosnia and Herzegovina) but we can also provide rental services at any destination across Bosnia, Croatia and Serbia. Our goal is to be the best in this sector with a wide range of premium vehicles and quality service. Our clients can pick up a car wherever they want, at the airport, at the bottom of the slopes of any ski resort, at the entrance to the villa or any hotel in any city. We can do this very efficiently in a very short period of time. All of our partners have many years of experience, thanks to which VIP Cars is focused on professional service and customer satisfaction. We guarantee exceptional quality of service, so each client can enjoy at any time.";
    home_top_text = "Are you looking for the luxurious options when it comes to car rental for your trip to Bosnia, Croatia or Serbia? If so, then you're in the ultimate place, as we offer nothing but the best when it comes to luxury cars.";
    logo_alt = "VIP CARS – Luxury Cars for Rent"; 
    phone_alt = "Get more information about our luxury cars";
    reserve_alt = "Fill in the form, ask for additional information and reserve your car";
    image_left1_alt = "Picture – Enjoy holidays with luxury Rent a Car";
    image_right1_alt = "Picture – Rent a Car in Bosnia, Serbia, Croatia";
    image_right2_alt = "Picture – Drive best cars in Bosnia, Serbia, Croatia";
    vehicles_alt = "Learn more about our luxury vehicles";
    gallery_alt = "See sortiment of „VIP CARS SARAJEVO“ luxury vehicles. ";
    vehicles_bottom_alt = "VIP CARS SARAJEVO rent luxury vehicles (cars) and professional drivers at all locations in BiH, Serbia, Croatia and Montenegro";
    locations_subtitle = "Our main office is in the street Ismeta Alajbegovića Šerbe 1A in Sarajevo, but our luxury car can be taken at any destination across BiH, Croatia, Serbia and Montenegro. Simply fill in the form or call us for more information.";
    s350d_title = "Mercedes 350d S: Lead the world";
    s350d_text = "The features forming a part of the sporty, elegant standard specification extrerior include the radiator trim with 3 louvres and Mercedes-Benz star on the bonnet, 45.7 cm (18-inch) 5-twin-spoke light-alloy wheels in high-sheen finish, Burmester® surround sound system with 13 speakers, AIR-BALANCE package, L6 diesel engine. Hallmark sedan characteristics lend this line visibly and tangibly more elegance and exclusivity."
  }

 else{
     home = "Početna";
    vehicles = "Vozila";
    locations = "Lokacija";
    about_us = "O nama";
    get_tarterd = "Iznajmite vozilo";
    intro_title = "Iznajmite luksuzan automobil i vozite se u stilu.";
    intro_subtitle = "ZA NEZABORAVAN UGOĐAJ";
    home_title = "Individualno, emocionalno i sportski";
    individual = "Individualnost";
    emotional = "emocija";
    luxury = "i luksuz";
    need_driver_title = "Potreban vam je vozač?";
    need_driver_text = "Kao dodatak našim automobilima, nudimo vam i usluge vozača. VIP Cars će osigurati da naši klijenti budu sigurni za vrijeme poslovnih i obiteljskih putovanja. Pored naših vozila nudimo i usluge profesionalnih vozača.";
    read_more = "Pročitajte više";
    renting_car_title = "Iznajmite VIP Cars vozilo";
    renting_car_text = "Put u Bosnu, Hrvatsku i Srbiju - bilo da je zbog posla ili zadovoljstva - je iskustvo koje uskoro neće zaboraviti. Mercedes V-Klasa, Mercedes Viano, Mercedes Vito, Volvo XC90 i drugi luksuzni brendovi dostupni su za najam na bilo kojoj destinaciji koju ste izabrali. Samo izaberite vozilo koje je idealno za Vaše potrebe i jedinstveni stil. Pogledajte koliko košta iznajmljivanje automobila za dan, vikend, cijelu nedjelju ili više slanjem upita za dodatne informacije i rezervaciju.";
    reserve_now = "Rezervišite odmah";
    legal_notice = "Uslovi korištenja";
    cookies = "Kolačići";
    privacy_statement = "Izjava o privatnosti";
    contact = "Kontakt";
    copy_text = "All rights reserved.";
    created_by = "Created by";
    vehicles_title = "V-Klasa - uživaj u luksuzu";
    v_class = "VIP CARS";
    exlusive = "EKSKLUZIVAN";
    exciting_luxury = "uzbudljiv luksuz";
    v_class_title = "Wow - Fascinirajuća V-Klasa";
    v_class_text1 = "Elegancija, stil, udobnost i prostranost definišu V klasu u ponudi VIP CARS SARAJEVO. Univerzalni koncept pruža pravo rješenje za sve i za svaku situaciju. Zahvaljujući svojim inovativnim sigurnosnim sistemima i prostranosti, V-Klasa je takođe savršeno porodično vozilo. Mercedes-Benz predstavlja luksuz u jedinstvenom formatu sa V-Class EXCLUSIVE, vrhunskim VIP šatlom i ekskluzivnim vozilom za duga putovanja.";
    v_class_text2 = "Izrazit enterijer daje V-Klasi uzbudljiv i nepredvidljiv osjećaj putničkog vozila kombinovanog sa izuzetnom pouzdanošću. Ovo VIP CARS luksuzno vozilo možete iznajmiti isključivo uz jednog od naših profesionalnih vozača.";
    viano_title = "Spoj elegancije i udobnosti uz Viano";
    viano_text = "Viano je opremljen svime što je potrebno za potpuno porodično vozilo. Sa prostorom za do osam ljudi i izuzetno fleksibilnim putničkim prostorom, on poseduje sve potrebne akreditive za putovanja na glavne svijetske destinacije: od vrtića i fudbalskih terena do tržnih centara, odmarališta i zabavnih parkova. Luksuzno vozilo „Mercedes Viano“ možete iznajmiti sa ili bez profesionalnog vozača.";
    volvo_title = "Čarobni - Volvo XC90";
    volvo_text = "Luksuzni sportski terenac XC90 naša je ideja vrhunskog luksuza. Spoj savršene sigurnosti, sofisticirane snage, neusporedive udobnosti i elegantnog dizajna omogućava Vam svakodnevnu vožnju u prvom razredu. Unutrašnjost XC90 impresionirat će Vas posebno odabranim materijalima i intuitivnom tehnologijom, a vožnju će obilježiti osjećaj snage i tišina kabine. Luksuzni VIP CARS auto „Volvo XC90“ možete iznajmiti sa ili bez profesionalnog vozača.";
    vito_title = "Jedan za sve: Svestrani Vito";
    vito_text = "Čvrst eksterijer i visokokvalitetni enterijer, maksimalna korist i niski troškovi - Mercedes-Benz Vito je svestrani profesionalac u segmentu kombija srednje veličine između 2,5 t i 3,2 t ukupne težine vozila. Raznolikost i varijabilnost kombija, Mixto i Tourer, čine Mercedes-Benz kombi srednje veličine specijalistom i svestranim modelom za širok spektar kupaca. Kao prvi kombi u segmentu vozila srednje veličine, nudi tri tipa pogona kao i mnoge inovativne sisteme sigurnosti i pomoći.";
    locations_title = "Pronađite nas vrlo lahko";
    you_can = "Naš";
    find_us = "URED";
    easily = "Sarajevo - Unitic";
    address = "Adresa";
    phone = "Telefon";
    email = "Email";
    about_us_title = "Nekoliko riječi o nama";
    a_couple = "Nekoliko";
    of_words = "riječi";
    about_vip_cars_sarajevo = "O VIP CARS SARAJEVO";
    about_us_text1 = "VIP Cars je rent-a-car luksuznih vozila koja pored vlastitih vozila nudi i usluge profesionalnih vozača. Glavni ured nam se nalazi u ulici Ismeta Alajbegovića Šerbe 1A u Sarajevu, ali također možemo pružati usluge najma na bilo kojem odredištu širom BiH, Hrvatske i Srbije.";
    about_us_text2 = "Naš cilj je da budemo najbolji u ovom sektoru sa širokim spektrom premium vozila i kvalitetnom uslugom. Naši klijenti mogu preuzeti vozilo gdje god žele, na aerodromu, na dnu padina bilo kojeg skijališta, na ulazu u vilu ili bilo kojem hotelu u bilo kojem gradu. To možemo učiniti vrlo efikasno u vrlo kratkom vremenskom roku.";
    about_us_text3 = "Svi naši partneri imaju dugogodišnje iskustvo, zahvaljujući tome VIP Cars je fokusiran na profesionalnu uslugu i zadovoljstvo klijenata. Garantujemo izuzetnu kvalitetu usluge, tako da svaki klijent može uživati u svakom trenutku.";
    reserve_title = "Iznajmite vaš automobil";
    reserve_subtitle = "Želite unajmiti automobil? Imate pitanje? Unesite vaše podatke kako bi mogli doći do vas, te očekujte brz odgovor.";
    contact_name = "Ime i prezime";
    date = "Datum";
    pick_up_date = "Datum preuzimanja vozila";
    drop_date = "Datum predavanja vozila";
    pick_up_location = "Lokacija preuzimanja vozila";
    drop_location = "Lokacija predavanja vozila";
    vehicle = "Vozilo";
    additions = "Dodaci";
    comments = "Komentari";
    privacy_policy = "Izjava o privatnosti";
    cancel = "Zatvori";
    send = "Pošalji";
    required_field = "Ovo polje je obavezno.";
    select_pick_up_location = "Izaberite lokaciju preuzimanja";
    same_as_pick_up_location = "Isto kao lokacija preuzimanja";
    select_vehicle = "Izaberite vozilo";
    driver = "Vozač";
    guide = "Vodič";
    thankyou_title = "Hvala vam!";
    thankyou_text = "Vaša forma je uspješno poslana. Naše osoblje će Vas kontaktirati u što skorije vrijeme putem telefona ili email adrese."
    not_found_title= "Greška 404 - Stranica ne postoji!";
    not_found_text = "Žao nam je, stranica koju pokušavate dohvatiti ne postoji. Pokušajte sa nekom od ponuđenih stranica u meniju iznad.";
    gallery_text = "Galerija vozila";
    gallery_text_v_class = "V-Klasa galerija vozila";
    gallery_text_viano= "Viano galerija vozila";
    gallery_text_s_class= "S-klasa galerija vozila";
    gallery_text_vito = "Vito galerija vozila";
    gallery_text_volvo = "Volvo galerija vozila";
    vehicles_text_bottom_p1 = "U ponudi imamo također i vozila koja ne spadaju u VIP kategoriju. Za više informacija kontaktirajte nas na ";
    vehicles_text_bottom_p2 = "ili email: ";
    your_name = "Vaše Ime";
    your_email = "Vaš Email";
    your_phone = "Vaš Telefon";
    with_driver = "samo sa vozačem";
    your_message = "Vaša poruka";
    additional_options = "Dodatne opcije";
    driver_included = "U vašu ponudu je uključena usluga vozača.";
    guide_included = "U vašu ponudu je uključena usluga vodiča.";
    home_bottom_text = "VIP Cars je rent-a-car luksuznih vozila koja pored vlastitih vozila nudi i usluge profesionalnih vozača. Glavni ured nam se nalazi u ulici Ismeta Alajbegovića Šerbe 1A u Sarajevu, ali također možemo pružati usluge najma na bilo kojem odredištu širom BiH, Hrvatske i Srbije. Naš cilj je da budemo najbolji u ovom sektoru sa širokim spektrom premium vozila i kvalitetnom uslugom. Naši klijenti mogu preuzeti vozilo gdje god žele, na aerodromu, na dnu padina bilo kojeg skijališta, na ulazu u vilu ili bilo kojem hotelu u bilo kojem gradu. To možemo učiniti vrlo efikasno u vrlo kratkom vremenskom roku. Svi naši partneri imaju dugogodišnje iskustvo, zahvaljujući tome VIP Cars je fokusiran na profesionalnu uslugu i zadovoljstvo klijenata. Garantujemo izuzetnu kvalitetu usluge, tako da svaki klijent može uživati u svakom trenutku.";
    home_top_text = "Da li tražite luksuzan izbor kada je riječ o iznajmljivanju automobila za svoje putovanje u Bosnu, Hrvatsku ili Srbiju? Ako je tako, onda ste na pravom mjestu, jer mi nudimo samo najbolje kada je riječ o luksuznim automobilima.";
    logo_alt = "VIP CARS SARAJEVO – luksuzna auta i profesionalni vozači dostupni za iznajmljivanje na svim lokacijama u regiji - BiH, Srbija Hrvatska i Crna Gora"; 
    phone_alt = "Više informacija o VIP CARS luksuznim autima";
    reserve_alt = "Ispunite formular, tražite dodatne informacije i rezervišite svoje luksuzno VIP CARS vozilo";
    image_left1_alt = "VIP CARS nudi luksuzna vozila (auta) za ugodan odmor";
    image_right1_alt = "VIP CARS SARAJEVO iznajmljuje auta na svim željenim lokacijama u Bosni, Srbiji, Hrvatskoj i Crnoj Gori";
    image_right2_alt = "VIP CARS SARAJEVO  iznajmljuje luksuzna vozila (auta) i profesionalne vozače";
    vehicles_alt = "Pogledajte asortiman luksuznih VIP CARS SARAJEVO vozila (auta)";
    gallery_alt = "Pogledajte asortiman luksuznih VIP CARS SARAJEVO vozila (auta)";
    vehicles_bottom_alt = "VIP CARS SARAJEVO  iznajmljuje luksuzna vozila (auta) i profesionalne vozače na svim lokacijama u BiH, Srbiji, Hrvatskoj i Crnoj Gori";
    locations_subtitle = "Glavni ured nam se nalazi u ulici Ismeta Alajbegovića Šerbe 1A u Sarajevu, ali naša luksuzna auta možete preuzeti na bilo kojem odredištu širom BiH, Hrvatske, Srbije i Crne Gore. Jednostavno ispunite formular ili nas nazovite za više informacija.";
    s350d_title = "Mercedes 350d S: Upravljaj svijetom";
    s350d_text = "Karakteristike koje čine dio sportske, elegantne standardne specifikacije uključuju eksternu radijatorsku opremu sa 3 žaluzine i Mercedes-Benz zvijezdu na haubi, 45,7 cm (18 inča) točkove od lahke legure sa 5 dvostrukih krakova u završnom sloju, Burmester® surround sistem sa 13 zvučnika, AIR-BALANCE paket, L6 dizel motor. Karakteristične karakteristike limuzine daju ovoj liniji vidljivo i opipljivo više elegancije i ekskluzivnosti.";
  }

 export {home, vehicles, locations, about_us, get_tarterd, intro_title, intro_subtitle, home_title,individual, emotional, luxury, need_driver_title, need_driver_text,read_more, renting_car_title, reserve_now,renting_car_text, legal_notice, cookies,
    privacy_statement, contact, copy_text, created_by, vehicles_title,v_class, exlusive, exciting_luxury, v_class_title, v_class_text1, v_class_text2, locations_title,you_can,find_us, easily, address, phone, email, about_us_title,
    a_couple, of_words, about_us_text1, about_us_text2, about_us_text3 ,reserve_title, reserve_subtitle, contact_name, date, pick_up_location, drop_location, pick_up_date, drop_date, vehicle, additions, comments, privacy_policy, cancel, send, required_field,
    select_pick_up_location, same_as_pick_up_location, select_vehicle, driver, guide, thankyou_title, thankyou_text,not_found_title, not_found_text, viano_title, viano_text, volvo_title, volvo_text, gallery_text,
    vehicles_text_bottom_p1, vehicles_text_bottom_p2,your_name, your_email,your_phone,with_driver, your_message, additional_options, driver_included, guide_included, home_bottom_text, home_top_text,
    logo_alt, phone_alt, reserve_alt, image_left1_alt, image_right1_alt, image_right2_alt, vehicles_alt, gallery_alt, vehicles_bottom_alt, locations_subtitle, about_vip_cars_sarajevo, gallery_text_v_class, gallery_text_viano,
    gallery_text_vito, gallery_text_volvo, vito_text, vito_title, s350d_title, s350d_text,gallery_text_s_class
};