import React from 'react';
import Link from 'gatsby-link'
import Date from './datePicker'
import {contact_name, email, phone, pick_up_location, drop_location, vehicle, comments, privacy_policy, cancel, send, required_field
,select_pick_up_location, same_as_pick_up_location, select_vehicle, driver, guide, your_name, your_email,your_phone, your_message,with_driver, date, additional_options,
driver_included, guide_included} from '../strings'
import {prefix, link_privacy} from '../langPrefix'

class FormReserve extends React.Component {
  constructor () {
    super()
    this.state = {
      isHiddenName: true,
      isHiddenEmail: true,
      isHiddenPhone: true,
      isEnabled: false,
      name : '',
      email : '',
      phone : '',
      driver_checked: false,
      guide_checked: false
    };
    this.handleChangeDriver = this.handleChangeDriver.bind(this);
    this.handleChangeGuide = this.handleChangeGuide.bind(this);

    this.updateInputName = this.updateInputName.bind(this);
    this.updateInputEmail = this.updateInputEmail.bind(this);
    this.updateInputPhone = this.updateInputPhone.bind(this);
    }

    enableButton(){
      if(this.state.name !== "" && this.state.email !== "" && this.state.phone !== "")
      {
        this.setState({isEnabled:true})
      } 
    }

    updateInputName(event){
      this.setState({name : event.target.value})
    }

    updateInputEmail(event){
      this.setState({email : event.target.value})
    }

    updateInputPhone(event){
      this.setState({phone : event.target.value})
    }

  errorName () {
    if(this.state.name === "")
    {
      this.setState({
        isHiddenName: false,
        isEnabled: false
      })
    }
    else{
      this.setState({
        isHiddenName: true,
        isEnabled: true
      })
    } 
  }

  errorEmail () {
    if((this.state.email === ""))
    {
      this.setState({
        isHiddenEmail: false,
        isEnabled: false
      })
    }
    else{
      this.setState({
        isHiddenEmail: true,
        isEnabled: true
      })
    } 
  }

  errorPhone () {
    if(this.state.phone === "")
    {
      this.setState({
        isHiddenPhone: false,
        isEnabled: false
      })
    }
    else{
      this.setState({
        isHiddenPhone: true,
        isEnabled: true
      })
    } 
  }

  handleChangeDriver() {
    this.setState({
      driver_checked: !this.state.driver_checked
    })
  }

  handleChangeGuide() {
    this.setState({
      guide_checked: !this.state.guide_checked
    })
  }

  render () {
    const contentDriver = this.state.driver_checked 
    ? <p className="included"> {driver_included} </p>
    : null;

    const contentGuide = this.state.guide_checked 
    ? <p className="included"> {guide_included} </p>
    : null;

    return (
      <div>
      <form name="reserve" className="form" method="POST" action="/thankyou" data-netlify="true" data-netlify-honeypot="bot-field" onBlur={this.enableButton}>
      <input type="hidden" name="form-name" value="reserve" /> 
      <label htmlFor="name">{contact_name}*</label>
       <input type="text" id="name" name="full_name" placeholder={your_name} onBlur={this.errorName.bind(this)} onChange={this.updateInputName}/>
       {!this.state.isHiddenName && <div className="error-message"> {required_field}</div>}

       <label htmlFor="email">{email}*</label>
       <input type="email" id="email" name="email" placeholder={your_email} onBlur={this.errorEmail.bind(this)} onChange={this.updateInputEmail}/>
        {!this.state.isHiddenEmail && <div className="error-message"> {required_field}</div>}

       <label htmlFor="phone">{phone}*</label>
       <input type="text" id="phone" name="phone" placeholder={your_phone}  onBlur={this.errorPhone.bind(this)} onChange={this.updateInputPhone}/>
        {!this.state.isHiddenPhone && <div className="error-message"> {required_field}</div>}

        <label htmlFor="date">{date}</label>
       <Date/>
        <label htmlFor="pickLocation">{pick_up_location}</label>
        <select name="pickLocation" id="pickLocation">
        <option value="" className="first">{select_pick_up_location}</option>
          <option value="Sarajevo">Sarajevo</option>
          <option value="Zenica">Zenica</option>
          <option value="Mostar">Mostar</option>
          <option value="Banja Luka">Banja Luka</option>
          <option value="Tuzla">Tuzla</option>
        </select>

  
        <label htmlFor="dropLocation">{drop_location}</label>
        <select name="dropLocation" id="dropLocation">
          <option value="sameAsPick">{same_as_pick_up_location}</option>
          <option value="Sarajevo">Sarajevo</option>
          <option value="Zenica">Zenica</option>
          <option value="Mostar">Mostar</option>
          <option value="Banja Luka">Banja Luka</option>
          <option value="Tuzla">Tuzla</option>
        </select>

        <label htmlFor="vehicle">{vehicle}</label>
        <select name="vehicle" id="vehicle">
          <option disabled value="">{select_vehicle}</option>
          <option value="Mercedes-Benz Vito">Mercedes-Benz Vito (7+1)</option>
          <option  value="Mercedes-Benz V Class 250">Mercedes-Benz V Class 250 - {with_driver} (6+1)</option>
          <option value="Mercedes-Benz Viano">Mercedes-Benz Viano (7+1)</option>
          <option value="Volvo XC90">Volvo XC90 (6+1)</option>
          <option value="Mercedes 350d S">Mercedes 350d S</option>
        </select>

      <label htmlFor="additions">{additional_options}</label>
       <input type="hidden" id="additions" name="additions"/>

       <div className="checkbox">
           <label htmlFor="driver" className="driver">
           <input type="checkbox" field="driver" id="driver" name="driver" checked={ this.state.driver_checked } onChange={ this.handleChangeDriver }/>
           
               <div className="image"><span>{driver}</span></div>
           </label>
          
            <label htmlFor="guide" className="guide">
           <input type="checkbox" field="guide" id="guide" name="guide" checked={ this.state.guide_checked } onChange={ this.handleChangeGuide }/>
           <div className="image"><span>{guide}</span></div>
           </label>

            <label htmlFor="more" className="more">
           <input type="checkbox" field="more" id="more" name="more"/>
           <div className="image"><span></span></div>
           </label>
       </div>
      { contentDriver }
      { contentGuide }
       <label htmlFor="comments">{your_message}</label>
       <textarea field="comments" id="comments" name="comments"></textarea>
       
       <div className="send-box">
       <Link to={prefix + link_privacy} className="link is-left">{privacy_policy}</Link>
       <Link to={prefix + "/"}  className="link is-right">{cancel}</Link>
       {this.state.isEnabled && <button type="submit"   className="button gold is-right">{send}</button>}
       {!this.state.isEnabled && <button type="submit" disabled className="button gold is-right">{send}</button>}
       
       </div>

        
   </form>
 
   </div>
    )
  }
}


export default FormReserve