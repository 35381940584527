import React from 'react'
import Link from 'gatsby-link'
import FormReserve from '../../components/ReservePage/form'
import {reserve_title, reserve_subtitle} from '../../components/strings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import LayoutEn from '../../components/layoutEn'


const ReservePage = () => (
    <LayoutEn>
    <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' className="section reserve">
  <Link to="/en/" className="close-btn"><FontAwesomeIcon icon="times"/></Link>
  <a href="tel:062 520 556" className="reserve-phone">062 520 556</a>
         <div className="container">
      
              <div className="title-box">
                  <h2 className="">{reserve_title}</h2>
                  <p className="">{reserve_subtitle}</p>   
              </div>
          <FormReserve/>
      </div>
   </ScrollAnimation>
   </LayoutEn>
)

export default ReservePage
